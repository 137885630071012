import React from 'react';
import { NotFound } from '@pwc-new-ventures/ui';
import Layout from '../layouts';

export default function() {
  return (
    <Layout title="404: Not found">
      <NotFound />
    </Layout>
  );
}
